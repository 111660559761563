.loading{
    display: flex;
    flex-direction: column;
    gap: 1vh;
    width: 100%;
    justify-content: center;
    align-items: center;

    color: #82101E;
    font-size: 3em;
    font-family: 'Exo 2', sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

}

.spinner {
    border: 0.2rem solid rgba(0, 0, 0, 0.1);
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
    border-left-color: #F4586E;
    animation: spin 1s ease infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}