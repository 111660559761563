.footer {
    margin-top: 3.75rem;
    background-color: #FEEFEF;
}

.navbarItem {
    margin: 0 0.9rem;
}

.navbarItem a {
    font-family: 'Noto Sans HK', sans-serif;
    color: #82101E;
    text-decoration: none;
}

/* The follow is for AI ChatBot */
.vector {
    position: fixed;
    width: 8rem;
    height: 8rem;
    bottom:3rem;
    right:3rem;
    background: url(/images/ChatCircleDots.png);
    background-size: 100% 100%;
    z-index: 3;
    border:none;
  }



:root {
  --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
}

/* .main_container {
  overflow: hidden;
}

.main_container,
.main_container * {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  outline: 0;
} */

.main_container {
  position: fixed;
  display: flex;
  width: 24.375rem;
  height: 39.813rem;
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 1.3rem;
  box-shadow: 0rem 0rem 1rem rgba(206, 42, 64, 0.25);
  z-index: 4;
  bottom:6rem;
  right:12rem;
  flex-direction: column;
  flex-wrap:wrap;
}

.chatbot_header{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: row;
    border-bottom: 0.1rem solid rgba(206, 42, 64, 0.25);
    position: relative;
}

.chatbot_header img{
    object-position: center;
    width:5rem;
}

.banker {
    height: 1.125rem;
    color: #82101E;
    font-family: Noto Sans, var(--default-font-family);
    font-size: 0.943rem;
    font-weight: 600;
    text-align: left;
  }

.header_right{
    display: flex;
    height: 2rem;
    position: absolute;
    bottom:0.3rem;
    right:2rem;
}

.insightful_precise {
    display: flex;
    position: relative;
    color: #4F050E;
    font-family: Exo 2,"Semibold", sans-serif;
    font-size: 0.75rem;
    font-weight: 600;
    padding-left:1rem;
    margin-bottom: auto;
  }
  .neobank_statistic {
    display: flex;
    position: relative;
    color: #D92121;
    font-family: Exo 2, "Medium", sans-serif;
    font-style: italic;
    font-size: 0.75rem;
    font-weight: 500;
    padding-left: 1rem;
  }

/* chatbox的container */
.chatbot_messages {
    height: 30rem;
    padding: 0.4rem;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
}

.userMessageBubble {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 17.938rem;
    padding: 0.625rem;
    background: #ffffff;
    border: 0.026rem solid #ffa7a7;
    border-radius: 1.25rem  1.25rem 0.125rem 1.25rem ;
    justify-content: flex-start;
    font-family: Exo 2, var(--default-font-family);
    color:#D92121;
    font-size: 0.626rem;
    font-weight: 700;
    text-align: left;
    margin-top: 0.5rem;
    margin-left:auto;
  }

  .banker_gpt {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 1.063rem;
    color: #D92121;
    font-family: Exo 2, var(--default-font-family);
    font-size: 0.875rem;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    white-space: nowrap;
  }

  .botMessageBubble {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 17.938rem;
    padding: 1rem;
    background: #feefef;
    border: 0.2rem solid #feefef;
    border-radius: 1.25rem 1.25rem 1.25rem 0.125rem;
    font-size: 1rem;
    text-align: left;
    margin-top: 0.5rem;
    margin-right:auto;
  }

  .bottom_container{
    border-top: 0.1rem solid rgba(206, 42, 64, 0.25);
    display: flex;
    flex-wrap:wrap;
    flex-direction: row;
    position: absolute;
    bottom:0;
    padding: 0.3rem 0rem;
    background-color: white;
    z-index:5;
}

.li_tag {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 0.5rem;
    position: relative;
    overflow: hidden;
    padding:0.5rem 0.2rem 0rem 0.2rem;
  }
  
  .keyword {
    position: relative;
    height: 0.563rem;
    color: #D92121;
    font-family: Noto Sans HK, var(--default-font-family);
    font-size: 0.7rem;
    font-weight: 500;
    line-height: 0.563rem;
    text-align: center;
    display: flex;
    align-items: center;;
    padding: 0.5rem 0.604rem 0.5rem 0.604rem;
    background: #feefef;
    border: none;
    border-radius: 2.108rem;
  }
  
  .chatbotMessage {
    padding: 0.5rem;
  }

  .chatbotMessage input {
    background-color: #ffffff;
    box-shadow: 0 0 0.6rem #FBD0D6;
    width: 19rem;
    flex-grow: 1;
    padding: 0.3rem 1rem;
    border: none;
    border-radius: 5rem;
    font-size: 0.7rem;
    outline: #FBD0D6;
    }

    .chatbotMessage button {
    margin-left: 0.4rem;
    padding: 0.3rem 0.5rem;
    border: none;
    border-radius: 4rem;
    background: linear-gradient(128.33deg, #ec1421, #e56d47);
    color: #ffa7a7;
    font-size: 1.2rem;
    cursor: pointer;
    }

