.card_vertical {
    display: flex;
    flex-direction: column;
    padding: 1.25rem;
    gap: 0.7rem;
    border-radius: 0.3125rem;
    border: 0.1rem solid #F9F7F6;
    transition: filter 0.3s ease;
}

.card_vertical:hover {
    /* box-shadow: 0 0 0.25rem #801f2b; */
    filter: brightness(0.9); 
    background-color: #FAFAFA;
}

.card {
    display: flex;
    grid-template-columns: 1fr 2fr;
    padding: 0.7rem;
    gap: 1.25rem;
    border-radius: 0.3125rem;
    border: 0.1rem solid #F9F7F6;
    transition: filter 0.3s ease;
    width: 107.5rem;
    
}


.card_no_img {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0.7rem;
    gap: 1.25rem;
    border-radius: 0.3125rem;
    border: 0.1rem solid #F9F7F6;
    transition: filter 0.3s ease;
}


.card_no_img2container {
    width: 100%;
    height: auto;
}


.card_no_img2 {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0.7rem;
    gap: 1.25rem;
    border-radius: 0.3125rem;
    border: 0.1rem solid #F9F7F6;
    transition: filter 0.3s ease;
    width: 108.5rem;
    height: 14.5rem;
    padding-top: 0.7rem;
}




.card_no_img:hover {
    /* box-shadow: 0 0 0.25rem #801f2b; */
    filter: brightness(0.9);
    background-color: #FAFAFA;
}

.card:hover {
    /* box-shadow: 0 0 0.25rem #801f2b; */
    filter: brightness(0.9); 
    background-color: #FAFAFA;
}


.card_content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footnote {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}





.card_img_vertical {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card_img_vertical img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.25rem;
}

.card_img {
    display: flex;
    justify-content: center;
    align-items: center;
    
    overflow: hidden;
}

.card_img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 0.25rem;
}

.tags {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
}

.tag {
    color: #FFA8A8;
    font-family: Inter, serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 1.5rem */
    letter-spacing: -0.019rem;
    padding-left: 1.5rem;
}

.date {
    color: #B09EA0;
    font-family: "Exo 2", sans-serif;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-left: 100rem;
}

.card_title {
    color: #4F050E;
    font-family: "Exo 2", sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 1.5rem;
}

.card_preview {
    color: #B09EA0;
    font-family: "Exo 2", "Noto Sans HK", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 4.125rem;
    padding-left: 1.5rem;
    white-space: nowrap;
}


.news_img {
    display: flex;
    position: absolute;
    width: 15rem;
    height: 7rem;
    margin-left: 91rem;
    padding-bottom: 1.3rem;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}