.bank {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 1rem;
    border-radius: 0.9rem;
    transition: filter 0.3s ease;
}

.bank:hover{
    filter: brightness(0.9);
    background-color: #FAFAFA;
}

.bankinfo {
    display: flex;
    flex-direction: row;
    align-items: center;

}


@media screen and (max-width: 60rem) {
    .banklist {
        display: flex;
        flex-direction: column;
    }

}

.bankico {

    border-radius: 0.5rem;
    margin: 1rem;
    height: 5rem;
    width: 5rem;
    object-fit: contain;
}

.bankTitle {
    margin: 0;
    padding: 0;
}

.bankTitle h2 {
    color: #4F050E;
    font-family: "Exo 2", sans-serif;
    font-size: 2.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.bankTitle p {
    color: #FFA8A8;
    font-family: "Exo 2", sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


