/* banner */
.banner {
    width: 100%;
    color: #F0435A;
    font-size: 4.8rem;
    font-family: 'Exo 2', sans-serif;
    margin-top: 3.5rem;

    text-align: center;
    white-space: pre-wrap;

}
@media screen and (max-width: 60rem) {
    .banner {
        font-size: 8rem;
    }
}
.darker {
    color: #82101E;
}

/* Search bar function */
.searchSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3.25rem;
    margin-bottom: 7rem;
}

.search_box {
    width: 60%;

}

.search {
    width: 100%;
    margin: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.inputField {
    margin: 1rem;
    border-radius: 2.85rem;
    border: 0.05rem solid #FFA8A8;
    outline: none;
    box-shadow: 0 0 0.2rem 0 rgba(240, 67, 90, 0.40);
    background-image: url("/images/search.png");
    background-repeat: no-repeat;

}

.inputField::placeholder {
    color: #4E5969;
    font-weight: 350;
    font-size: 1.875rem;
}

/* search button */
.searchButton {
    height: 4.15rem;
    width: 12.5rem;
    border: #82101E;
    border-radius: 5.25rem;
    outline: none;
    margin: 1rem;
    background: linear-gradient(128deg, #ED1522 0%, #E56E48 100%);
    color: #FFA8A8;
    font-size: 1.5rem;
    font-family: 'Noto Sans HK', sans-serif;
    font-weight: 500;

}
@media screen and (max-width: 60rem) {
    .search_box{
        width: 80%;
    }
    .searchButton {
        width: 18rem;
    }
}
.searchButton:hover {
    background: linear-gradient(128deg, #ff2241 0%, #980422 100%);

}

.searchButton:active {
    background: linear-gradient(128deg, #ff2241 0%, #980422 100%);
    box-shadow: inset 1rem 1rem 3rem #801f2b,
    inset -1rem -1rem 3rem #ad2b3b;
}

/* popular search list */
.popularSearch {
    margin: 1rem;
    color: #CE2A40;
    font-size: 1.5rem;
}

.popularSearchList {
    width: 55.55%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.pinkbutton {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FEEFEF;
    padding: 1rem 1rem;
    font-size: 1.5rem;
    font-family: 'Exo 2', sans-serif;
    font-weight: 500;
    margin: 0.5rem 0.25rem;
    color: #CE2A40; 
    border-radius: 20rem;
}

.pinkbutton:hover {
    background: #ff9b9b;
}

.pinkbutton:active {
    background: #FFA8A8;
}

.recentNewsSection {
    width: calc(66.66% - 1rem);
    display: flex;
    flex-direction: column;
}

.recentNewsHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

}

.headerText {
    display: flex;
    flex-direction: column;

}

.headerText h1 {
    margin: 0;
    color: #F0435A;
    font-size: 1.8rem;
    font-family: 'Exo 2', sans-serif;
    font-weight: 700;
}

.headerText p {
    margin: 0.5rem 0 0 0;
    color: #B09EA0;
    font-family: "Exo 2", sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


.news_time_constrain {
    display: flex;
    height: 3rem;
    padding: 0.5rem 1.25rem;
    align-items: center;
    justify-content: center;
    border-radius: 6rem;
    background: #F9F7F6;

    color: #D92121;
    font-family: "Noto Sans HK", sans-serif;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: normal;
}

.recentNewsSelector {
    display: flex;
    align-items: center;

}

.recentNewsSelector p {
    margin: 0.25rem;
    color: #FFA8A8;
    font-size: 1.2rem;
    font-family: 'Exo 2', sans-serif;
    font-weight: 500;
}
@media screen and (max-width: 60rem) {
    .recentNewsSection {
        font-size: 4rem;
    }
}
.recentNewsContent {
    display: grid;
    gap: 2.5rem;
    grid-template-columns: 12fr;
}

.news p {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(12, 1fr);
    gap: 1rem 2.5rem;
    grid-auto-flow: dense;
    grid-template-areas:
    "a a a a a b b b b b b b"
    "a a a a a b b b b b b b"
    "a a a a a c c c c c c c"
    "a a a a a c c c c c c c"
    "a a a a a d d d d d d d"
    "a a a a a d d d d d d d"
    "e e e e e e f f f f f f"
    "e e e e e e f f f f f f"
    "g g g g g g h h h h h h"
    "g g g g g g h h h h h h"
    "i i i i i i j j j j j j"
    "i i i i i i j j j j j j";
}


.a {
    grid-area: a;
}

.b {
    grid-area: b;
}

.c {
    grid-area: c;
}

.d {
    grid-area: d;
}

.e {
    grid-area: e;
}


.f {
    grid-area: f;
}

.g {
    grid-area: g;
}

.h {
    grid-area: h;
}

.i {
    grid-area: i;
}

.j {
    grid-area: j;
}

@media screen and (max-width: 60rem) {
    .news {
        display: flex;
        flex-direction: column;
    }
}

/* learn More button */
.learnMore {
    margin-top: 2rem;
}

.learnMore a {
    color: #FFA8A8;
    font-size: 1rem;
    font-family: 'Exo 2', serif;
    font-weight: 500;
    text-decoration-line: underline;
    float: right;
}

/* product section */
.ProductSection {
    margin-top: 6.25rem;
    width: 66.66%;
    padding: 3.12rem 3.75rem;
    display: flex;
    flex-direction: column;
}


.productHeader {
    display: flex;
    flex-direction: column;
}

.productHeader h1 {
    display: flex;
    flex-direction: row;
    gap: 0.62rem;
    color: #D92121;
    font-size: 1.8rem;
    font-family: 'Exo 2', sans-serif;
    font-weight: 700;
}

.productHeader p {
    color: #B09EA0;
    font-family: "Exo 2", sans-serif;
    font-size: 1.25rem;
    font-weight: 500;
}

.products_container {
    display: grid;
    grid-template-columns: 6fr 4fr;
    gap: 1.25rem;
    padding: 2.5rem 3.75rem;

    border-radius: 3.75rem;
    border: 0.1rem solid #F4586E;
    background: rgba(254, 239, 239, 0.70);
}

@media screen and (max-width: 90rem) {
    .products_container {
        display: flex;
        flex-direction: column;
    }
}

/* popular Banks Section */
.popularBanksSection {
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
    padding: 2.5rem;
    width: 66.66%;
    border-radius: 0.5rem;
    border: 0.1rem solid #F9F7F6;
    background-size: 100%;
    background-blend-mode: hard-light, normal;
}

.banklist {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.arrowimg {
    width: 15%;
}

.box {
    display: grid;
    grid-template-columns: 6.4fr 3.2fr;
    gap: 2rem;
    width: 66.66%;
    margin-top: 6.25rem;
    align-items: center;
}

@media screen and (max-width: 70rem) {
    .box {
        display: flex;
        flex-direction: column;
    }

}

.researchReportSection {
    width: 100%;
    margin-bottom: 5rem;
    padding: 2.5rem 2rem 3rem 2rem;
    border-radius: 3rem;
    border: 0.05rem solid #F9F7F6;
    overflow: auto;

}

.report {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
}

.report img {
    width: 5rem;
    height: 6.5rem;
    margin-right: 2rem;
    object-fit: cover;
}

.reportTitle {
    display: flex;
    flex-direction: column;
}

.reportTitle h2 {
    color: black;
}

.reportTitle p {
    color: rgb(128, 128, 128);
}

.nbanker {
    display: flex;
    padding: 5rem 2rem 3rem 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3.75rem;

    border-radius: 3rem;
    background: linear-gradient(100deg, #ED1522 -1.65%, #E56E48 82.54%);
}

.text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    color: #FEEFEF;
    font-size: 1.1rem;
    font-family: Noto Sans HK, sans-serif;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.product_bank {
    display: flex;
    flex-direction: column;
}

.product_bank h1 {
    color: #D92121;
    font-family: "Exo 2", sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.link {
    color: #FFA8A8;
    text-align: right;
    font-family: "Exo 2", sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
}




/* The follow is for AI ChatBot */
.vector {
    position: fixed;
    width: 8rem;
    height: 8rem;
    margin: 30px 0 0 30px;
    bottom:3rem;
    right:3rem;
    background: url(/images/ChatCircleDots.png);
    background-size: 100% 100%;
    z-index: 3;
    border:none;
  }



:root {
  --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
}

/* .main_container {
  overflow: hidden;
}

.main_container,
.main_container * {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  outline: 0;
} */

.main_container {
  position: fixed;
  display: flex;
  width: 24.375rem;
  height: 39.813rem;
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 1.3rem;
  box-shadow: 0rem 0rem 1rem rgba(206, 42, 64, 0.25);
  z-index: 4;
  bottom:6rem;
  right:12rem;
  flex-direction: column;
  flex-wrap:wrap;
}

.chatbot_header{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: row;
    border-bottom: 0.1rem solid rgba(206, 42, 64, 0.25);
    position: relative;
}

.chatbot_header img{
    object-position: center;
    width:5rem;
}

.banker {
    height: 1.125rem;
    color: #82101E;
    font-family: Noto Sans, var(--default-font-family);
    font-size: 0.943rem;
    font-weight: 600;
    text-align: left;
  }

.header_right{
    display: flex;
    height: 2rem;
    position: absolute;
    bottom:0.3rem;
    right:2rem;
}

.insightful_precise {
    display: flex;
    position: relative;
    color: #4F050E;
    font-family: Exo 2,"Semibold", sans-serif;
    font-size: 0.75rem;
    font-weight: 600;
    padding-left:1rem;
    margin-bottom: auto;
  }
  .neobank_statistic {
    display: flex;
    position: relative;
    color: #D92121;
    font-family: Exo 2, "Medium", sans-serif;
    font-style: italic;
    font-size: 0.75rem;
    font-weight: 500;
    padding-left: 1rem;
  }

/* chatbox的container */
.chatbot_messages {
    height: 30rem;
    padding: 0.4rem;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
}

.userMessageBubble {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 17.938rem;
    padding: 0.625rem;
    background: #ffffff;
    border: 0.026rem solid #ffa7a7;
    border-radius: 1.25rem  1.25rem 0.125rem 1.25rem ;
    justify-content: flex-start;
    font-family: Exo 2, var(--default-font-family);
    color:#D92121;
    font-size: 0.626rem;
    font-weight: 700;
    text-align: left;
    margin-top: 0.5rem;
    margin-left:auto;
  }

  .banker_gpt {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 1.063rem;
    color: #D92121;
    font-family: Exo 2, var(--default-font-family);
    font-size: 0.875rem;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    white-space: nowrap;
  }

  .botMessageBubble {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 17.938rem;
    padding: 0.625rem;
    background: #feefef;
    border: 1px solid #feefef;
    border-radius: 1.25rem 1.25rem 1.25rem 0.125rem;
    font-size: 0.626rem;
    text-align: left;
    margin-top: 0.5rem;
    margin-right:auto;
  }

  .bottom_container{
    border-top: 0.1rem solid rgba(206, 42, 64, 0.25);
    display: flex;
    flex-wrap:wrap;
    flex-direction: row;
    position: absolute;
    bottom:0;
    padding: 0.3rem 0rem;
    background-color: white;
    z-index:5;
}

.li_tag {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 0.5rem;
    position: relative;
    overflow: hidden;
    padding:0.5rem 0.2rem 0rem 0.2rem;
  }
  
  .keyword {
    position: relative;
    height: 0.563rem;
    color: #D92121;
    font-family: Noto Sans HK, var(--default-font-family);
    font-size: 0.7rem;
    font-weight: 500;
    line-height: 0.563rem;
    text-align: center;
    display: flex;
    align-items: center;;
    padding: 0.5rem 0.604rem 0.5rem 0.604rem;
    background: #feefef;
    border: none;
    border-radius: 2.108rem;
  }
  
  .chatbotMessage {
    padding: 0.5rem;
  }

  .chatbotMessage input {
    background-color: #ffffff;
    box-shadow: 0 0 0.6rem #FBD0D6;
    width: 19rem;
    flex-grow: 1;
    padding: 0.3rem 1rem;
    border: none;
    border-radius: 10px;
    font-size: 8px;
    outline: #FBD0D6;
    }

    .chatbotMessage button {
    margin-left: 0.4rem;
    padding: 0.3rem 0.5rem;
    border: none;
    border-radius: 20px;
    background: linear-gradient(128.33deg, #ec1421, #e56d47);
    color: #ffa7a7;
    font-size: 1.2rem;
    cursor: pointer;
    }

