.product_card {
    padding: 1.25rem 0.62rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    justify-content: center;
    align-items: center;
    height: auto;
    border-radius: 1.25rem;
    background: #FFFFFF;
}



.na_product_card {
    height: 100%;
    padding: 1.25rem 0.62rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    justify-content: center;
    align-items: center;
    width: 19.875rem;
    height: 18.813rem;
    border-radius: 1.25rem;
    background: #F9F7F6;
}

.na_product_card2 {
    height: 100%;
    padding: 1.25rem 0.62rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    justify-content: center;
    align-items: center;
    width: 318px;
    height: 351px;
    border-radius: 1.25rem;
    background: #F9F7F6;
}

.na_product_card p {
    color: #4E5969;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 1.625rem */
    text-transform: capitalize;
}

.product_type {
    color: #FFA8A8;
    text-align: center;
    font-family: Inter,sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 2.25rem */
    letter-spacing: -0.033rem;
}

.product_name {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    color: #4F050E;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 3rem;
    font-style: normal;
    font-weight: 500;
    line-height: 3.631rem;
    letter-spacing: -0.066rem;

    background: linear-gradient(100deg, #ED1522 -1.65%, #E56E48 82.54%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.na_product_name {
    color: #86909C;
    font-family: Inter, sans-serif;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 2.925rem */
    text-transform: capitalize;
}

.product_name_black{
    color: #1D2129;
    text-align: center;
    font-family: Inter,sans-serif;
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 4.5rem */
    letter-spacing: -0.066rem;
}

.product_card p {
    padding: 0 1.25rem;
    overflow: hidden;
    color: #B09EA0;
    text-overflow: ellipsis;
    font-family: Noto Sans HK, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    
}

.product_card ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.62rem;
    justify-content: center;
    align-items: center;
    list-style: none;
}

.product_card ul li {
    display: flex;
    padding: 0.62rem 1.25rem;

    color: #D92121;
    font-family: Noto Sans HK, sans-serif;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    border-radius: 6.54rem;
    background: #FEEFEF;
}