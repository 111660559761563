.header{
    width: 100%;
    display: grid;
    grid-template-columns: 7fr 3fr;
    background-color: rgba(255,255,255,0.9);
    backdrop-filter: blur(0.5rem);
    z-index: 10;
}

.navbar{
    width: 100%;
    display: flex;
    align-items: center;

}
.brand{
    margin: 0 0;
}

.navbarItemBox{
    width: 80%;
    display: flex;
}

.navbarItem{
    margin: 0.3rem;
    font-size: 1rem;
    font-weight: 600;
}

.navbarItem a{
    font-family: 'Exo 2', sans-serif;
    color: #82101E;
    text-decoration: none;
    margin-right: 10px;
    font-size: large;
}

.widgetBox{
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.itSolution{
    margin: 0.5rem;
    width: 5.5rem;
    height: 1.5rem;
    font-family: 'Exo 2', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.9rem;
    line-height: 1.45rem;
    /* identical to box height */


    background: linear-gradient(99.39deg, #F4586E -1.74%, rgb(253, 225, 225) 97.63%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

}

.userIco{
    margin: 0.5rem;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    overflow: hidden;
}

.userIco img{
    margin: 0 auto;
    height: 2rem;
    width: 2rem;
}

.languageSelector{
    margin: 0.5rem;
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 10%;
    overflow: hidden;


}

.languageSelector img{
    margin: 0 auto;
    width: 1.75rem;
    height: 1.75rem;
}

.searchBox{
    margin: 0.5rem;

    box-sizing: border-box;


    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0.5rem 3rem 0.5rem 1rem;
    gap: 0.75rem;

    width: 5.95rem;
    height: 2.45rem;

    border: 0.05rem solid #DE384E;
    border-radius: 2.85rem;

}