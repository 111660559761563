.tabBox{
    display:flex;
    flex-direction:column;
    margin-top: 1.5rem;
}

.tabBar{
    display:flex;
    gap: 0.5rem 1rem;
    flex-direction:row;
    align-items:center;
    flex-wrap: wrap;
}

.tabBar2ndStyle{
    display:flex;
    gap: 0.5rem 1.35rem;
    flex-direction:row;
    align-items:center;
    flex-wrap: wrap;
}

.inactiveTab2ndStyle{
    padding: 0.75rem 1.25rem;

    color:  #CE2A40;
    font-family: "Noto Sans HK", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    border-radius: 5.25rem;
    background:  #FEEFEF;

}

.activeTab2ndStyle{
    padding: 0.75rem 1.25rem;

    color:  #CE2A40;
    font-family: "Noto Sans HK", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    border-radius: 5.25rem;
    background:  #FFA8A8;
}

.inactiveTab2ndStyle:hover{
    background-color: #FFF6F6;
}

.inactiveTab2ndStyle:active{
    background-color: #FEEFEF;
}

.inactiveTab{
    padding: 0.65rem 1.25rem;
    color: #82101E;
    font-family: "Noto Sans HK", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28.96px;
    border-bottom: 0.1rem solid #F0435A;
}

.inactiveTab:hover{
    background-color: #FFF6F6;
}

.inactiveTab:active{
    background-color: #FEEFEF;
}

.activeTab{
    padding: 0.65rem 1.25rem;


    color: #82101E;
    font-family: "Noto Sans HK", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28.96px;
    background-color: #FEEFEF;
    border-bottom: 0.1rem solid #F0435A;
}

.tabContent {
    width: 100%;
}

.paginationBox{
    display:flex;
    flex-direction:column;
    
}


.paginationBoxFooter{
    width: 100%;
}
.paginationBoxFooterButtons{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    margin-top: 1.5rem;
}
.loadBtn{
    margin: 0 4rem;
    color:  #FFA8A8;
    text-align: right;
    font-family: "Exo 2", sans-serif;
    font-size: 0.85rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
}

.indexBox{
    max-width: 70%;
    display:flex;
    flex-wrap: wrap;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    cursor: pointer;
    /* border-left: 0.05rem solid #A75862; */
    padding: 0 0.85rem;
    /* border-right:0.05rem solid #A75862; */
}

.indexBox:hover{
    cursor: pointer;
}

.index{
    margin: 0 0.85rem;
    color:#A75862;
    font-family: "Exo 2", sans-serif;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.activeIndex{
    margin: 0 0.85rem;
    color: #F0435A;
    font-family: "Exo 2", sans-serif;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
}

.arrowButton {
    background-color: transparent;
    border: none;
    color: #980422;
    font-size: 24px;
}

