.profileMenu {
  position: relative;
}

.profileIconButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  height: 73px;
  width: 73px;
}

.profilePopover {
  position: absolute;
  top: 100%; /* Position below the button */
  right: 0;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 8px;
  display: flex;
  flex-direction: column;
}

.profilePopoverButton {
  margin: 5px 0;
  padding: 8px;
  width: 100%;
  background: none;
  cursor: pointer;
  font-family: "Exo 2";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
}

.profilePopoverButton:nth-child(-n+2) {
  color: #82101E;
}

.profilePopoverButton:last-child {
  background: white;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(100deg, #F4586E -1.65%, #FDE1E1 82.54%);
}

.profilePopoverButton:focus,
.profilePopoverButton:hover {
  text-decoration: underline;
  text-decoration-color: black;
  -webkit-text-decoration-color: black;
}