:root {
  --brown: rgba(176, 158, 160, 1);
  --grey: rgba(249, 247, 246, 1);
  --lightfillcolor-fill-1: rgba(247, 248, 250, 1);
  --lighttextcolor-text-2: rgba(78, 89, 105, 1);
  --med-20-font-family: "Noto Sans HK", Helvetica;
  --med-20-font-size: 20px;
  --med-20-font-style: normal;
  --med-20-font-weight: 500;
  --med-20-letter-spacing: 0px;
  --med-20-line-height: normal;
  --reddark: rgba(79, 5, 14, 1);
  --redfeefef: rgba(251, 208, 214, 1);
  --redmid: rgba(217, 33, 33, 1);
  --redmidhovered: rgba(130, 16, 30, 1);
  --redpink: rgba(254, 239, 239, 1);
  --redpinkhovered: rgba(255, 168, 168, 1);
  --white: rgba(255, 255, 255, 1);
}
