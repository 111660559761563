.filterContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 3.125rem auto 1.25rem;
  padding: 1.25rem;
  width: 80%;
  height: auto;
}

.header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: 20px;
}

.NewsAndLocationContainer {
  white-space: nowrap;
  position: relative;

}

.news {
  color: #D92121;
  font-family: "Exo 2";
  font-size: 6rem;
  font-family: Exo 2,"Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.inLocation {
  
  margin-top: 5.375rem;
  margin-left: 75rem;
  margin-right: 0.375rem;
  color: #FFA8A8;
  font-family: "Exo 2";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
  
  
}

.location {
  display: flex;
  padding-top: 16.25rem;
  padding: 0.5rem 1.25rem; /* 相当于 8px 20px / 16px */
  margin-top: 5rem; /* 相当于 8px / 16px */
  border-radius: 6.5449375rem; /* 相当于 104.719px / 16px */
  background: #FEEFEF;
  color: #D92121;
  font-family: "Noto Sans HK";
  font-size: 1.25rem; /* 相当于 20px / 16px */
  font-style: normal;
  font-weight: 501;
  line-height: normal;
  white-space: nowrap;
}



 /*  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 1.25rem;  相当于 8px 20px / 16px 
  margin-top: 0.5rem;  相当于 8px / 16px 
  border-radius: 6.5449375rem; /* 相当于 104.719px / 16px 
  background: #FEEFEF;
  color: #D92121;
  font-family: "Noto Sans HK";
  font-size: 1.25rem;  相当于 20px / 16px 
  font-style: normal;
  font-weight: 501;
  line-height: normal;
 */
 
 .filterOptions_container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
}



.filterOptions {
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 0.625rem;
  margin-right: 39rem;
}


.filterOptionsHeader {
  display: inline;
  flex-direction: row;
  justify-content: center;
  margin-right: 1.25rem;
  margin-bottom: 0.313rem;
  color: #D92121;
  font-family: "Noto Sans HK";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.466px;
}


.select_container{
  display: inline-flex;
  justify-content: center;
  position: relative;
  margin-left: 86rem;
  white-space: nowrap;
  transform: translateY(-3.8rem);
}


.select_container .icon_container{
  width: 2.7rem;
  height: 1.55rem;
  margin-left: -3rem;
  margin-top: -3rem;
  position: inherit;
  right: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #023ca9;
  background: url(/images/Vector.png);
  background-repeat: no-repeat;
  background-size: cover;
}

icon-container i{
  font-size: 30px;
  color: white;
}

.select_box{
  border: none;
  appearance: none;
  padding: 0 30px 0 15px;
  color: #D92121;
  background-color: #FEEFEF;
  font-family: "Noto Sans HK";
  font-size: 1.5rem; /* 相当于 20px / 16px */
  font-style: normal;
  font-weight: 501;
  line-height: normal;
  text-align: center;
}



.pinkbutton {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FEEFEF;
    padding: 0 0.4rem;
    font-size: 1rem;
    font-family: 'Exo 2', sans-serif;
    font-weight: 500;
    margin: 0.5rem 0.25rem;
}

.pinkbutton:active {
  background-color: pink;
  /* 其他样式 */
}

.pinkbutton:focus {
  background-color: pink;
  /* 其他样式 */
}

.divider {
  width: 108.5rem;
  height: 0.063rem;
  background-color: #ccc;
  margin-bottom: 1.25rem;
}


.card_img {
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(/images/WebLogo.png);
  overflow: hidden;
}

.paginationBoxFooter{
  width: 100%;
}
.paginationBoxFooterButtons{
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  margin-top: 1.5rem;
}
.loadBtn{
  margin: 0 4rem;
  color:  #FFA8A8;
  text-align: right;
  font-family: "Exo 2", sans-serif;
  font-size: 0.85rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
}

.indexBox{
  max-width: 70%;
  display:flex;
  flex-wrap: wrap;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  cursor: pointer;
  /* border-left: 0.05rem solid #A75862; */
  padding: 0 0.85rem;
  /* border-right:0.05rem solid #A75862; */
}

.indexBox:hover{
  cursor: pointer;
}

.index{
  margin: 0 0.85rem;
  color:#A75862;
  font-family: "Exo 2", sans-serif;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.activeIndex{
  margin: 0 0.85rem;
  color: #F0435A;
  font-family: "Exo 2", sans-serif;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}

.arrowButton {
  background-color: transparent;
  border: none;
  color: #980422;
  font-size: 24px;
}

