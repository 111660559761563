body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Exo 2',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(0.5rem + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Footer_footer__oAXFn {
    margin-top: 3.75rem;
    background-color: #FEEFEF;
}

.Footer_navbarItem__3KiZ- {
    margin: 0 0.9rem;
}

.Footer_navbarItem__3KiZ- a {
    font-family: 'Noto Sans HK', sans-serif;
    color: #82101E;
    text-decoration: none;
}

/* The follow is for AI ChatBot */
.Footer_vector__RLTr4 {
    position: fixed;
    width: 8rem;
    height: 8rem;
    bottom:3rem;
    right:3rem;
    background: url(/images/ChatCircleDots.png);
    background-size: 100% 100%;
    z-index: 3;
    border:none;
  }



:root {
  --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
}

/* .main_container {
  overflow: hidden;
}

.main_container,
.main_container * {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  outline: 0;
} */

.Footer_main_container__nbODA {
  position: fixed;
  display: flex;
  width: 24.375rem;
  height: 39.813rem;
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 1.3rem;
  box-shadow: 0rem 0rem 1rem rgba(206, 42, 64, 0.25);
  z-index: 4;
  bottom:6rem;
  right:12rem;
  flex-direction: column;
  flex-wrap:wrap;
}

.Footer_chatbot_header__3fKmk{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: row;
    border-bottom: 0.1rem solid rgba(206, 42, 64, 0.25);
    position: relative;
}

.Footer_chatbot_header__3fKmk img{
    object-position: center;
    width:5rem;
}

.Footer_banker__3t9b7 {
    height: 1.125rem;
    color: #82101E;
    font-family: Noto Sans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
    font-family: Noto Sans, var(--default-font-family);
    font-size: 0.943rem;
    font-weight: 600;
    text-align: left;
  }

.Footer_header_right__3RGmf{
    display: flex;
    height: 2rem;
    position: absolute;
    bottom:0.3rem;
    right:2rem;
}

.Footer_insightful_precise__ECwk4 {
    display: flex;
    position: relative;
    color: #4F050E;
    font-family: Exo 2,"Semibold", sans-serif;
    font-size: 0.75rem;
    font-weight: 600;
    padding-left:1rem;
    margin-bottom: auto;
  }
  .Footer_neobank_statistic__1S7yi {
    display: flex;
    position: relative;
    color: #D92121;
    font-family: Exo 2, "Medium", sans-serif;
    font-style: italic;
    font-size: 0.75rem;
    font-weight: 500;
    padding-left: 1rem;
  }

/* chatbox的container */
.Footer_chatbot_messages__3YX_g {
    height: 30rem;
    padding: 0.4rem;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
}

.Footer_userMessageBubble__2xZwA {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 17.938rem;
    padding: 0.625rem;
    background: #ffffff;
    border: 0.026rem solid #ffa7a7;
    border-radius: 1.25rem  1.25rem 0.125rem 1.25rem ;
    justify-content: flex-start;
    font-family: Exo 2, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
    font-family: Exo 2, var(--default-font-family);
    color:#D92121;
    font-size: 0.626rem;
    font-weight: 700;
    text-align: left;
    margin-top: 0.5rem;
    margin-left:auto;
  }

  .Footer_banker_gpt__3H8Jf {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 1.063rem;
    color: #D92121;
    font-family: Exo 2, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
    font-family: Exo 2, var(--default-font-family);
    font-size: 0.875rem;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    white-space: nowrap;
  }

  .Footer_botMessageBubble__FTSyS {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 17.938rem;
    padding: 1rem;
    background: #feefef;
    border: 0.2rem solid #feefef;
    border-radius: 1.25rem 1.25rem 1.25rem 0.125rem;
    font-size: 1rem;
    text-align: left;
    margin-top: 0.5rem;
    margin-right:auto;
  }

  .Footer_bottom_container__2Ip_0{
    border-top: 0.1rem solid rgba(206, 42, 64, 0.25);
    display: flex;
    flex-wrap:wrap;
    flex-direction: row;
    position: absolute;
    bottom:0;
    padding: 0.3rem 0rem;
    background-color: white;
    z-index:5;
}

.Footer_li_tag__3tb8E {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    position: relative;
    overflow: hidden;
    padding:0.5rem 0.2rem 0rem 0.2rem;
  }
  
  .Footer_keyword__2hLAN {
    position: relative;
    height: 0.563rem;
    color: #D92121;
    font-family: Noto Sans HK, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
    font-family: Noto Sans HK, var(--default-font-family);
    font-size: 0.7rem;
    font-weight: 500;
    line-height: 0.563rem;
    text-align: center;
    display: flex;
    align-items: center;;
    padding: 0.5rem 0.604rem 0.5rem 0.604rem;
    background: #feefef;
    border: none;
    border-radius: 2.108rem;
  }
  
  .Footer_chatbotMessage__1zu2r {
    padding: 0.5rem;
  }

  .Footer_chatbotMessage__1zu2r input {
    background-color: #ffffff;
    box-shadow: 0 0 0.6rem #FBD0D6;
    width: 19rem;
    flex-grow: 1;
    padding: 0.3rem 1rem;
    border: none;
    border-radius: 5rem;
    font-size: 0.7rem;
    outline: #FBD0D6;
    }

    .Footer_chatbotMessage__1zu2r button {
    margin-left: 0.4rem;
    padding: 0.3rem 0.5rem;
    border: none;
    border-radius: 4rem;
    background: linear-gradient(128.33deg, #ec1421, #e56d47);
    color: #ffa7a7;
    font-size: 1.2rem;
    cursor: pointer;
    }


.Header_header__35Bwg{
    width: 100%;
    display: grid;
    grid-template-columns: 7fr 3fr;
    background-color: rgba(255,255,255,0.9);
    -webkit-backdrop-filter: blur(0.5rem);
            backdrop-filter: blur(0.5rem);
    z-index: 10;
}

.Header_navbar__1adj-{
    width: 100%;
    display: flex;
    align-items: center;

}
.Header_brand__2qYxi{
    margin: 0 0;
}

.Header_navbarItemBox__1FX7n{
    width: 80%;
    display: flex;
}

.Header_navbarItem__3Lc7t{
    margin: 0.3rem;
    font-size: 1rem;
    font-weight: 600;
}

.Header_navbarItem__3Lc7t a{
    font-family: 'Exo 2', sans-serif;
    color: #82101E;
    text-decoration: none;
    margin-right: 10px;
    font-size: large;
}

.Header_widgetBox__CtqHT{
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.Header_itSolution__1VEnM{
    margin: 0.5rem;
    width: 5.5rem;
    height: 1.5rem;
    font-family: 'Exo 2', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.9rem;
    line-height: 1.45rem;
    /* identical to box height */


    background: linear-gradient(99.39deg, #F4586E -1.74%, rgb(253, 225, 225) 97.63%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

}

.Header_userIco__17Ili{
    margin: 0.5rem;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    overflow: hidden;
}

.Header_userIco__17Ili img{
    margin: 0 auto;
    height: 2rem;
    width: 2rem;
}

.Header_languageSelector__18PSO{
    margin: 0.5rem;
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 10%;
    overflow: hidden;


}

.Header_languageSelector__18PSO img{
    margin: 0 auto;
    width: 1.75rem;
    height: 1.75rem;
}

.Header_searchBox__2gTAy{
    margin: 0.5rem;

    box-sizing: border-box;


    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0.5rem 3rem 0.5rem 1rem;
    grid-gap: 0.75rem;
    gap: 0.75rem;

    width: 5.95rem;
    height: 2.45rem;

    border: 0.05rem solid #DE384E;
    border-radius: 2.85rem;

}
/* banner */
.HomeItem_banner__siixu {
    width: 100%;
    color: #F0435A;
    font-size: 4.8rem;
    font-family: 'Exo 2', sans-serif;
    margin-top: 3.5rem;

    text-align: center;
    white-space: pre-wrap;

}
@media screen and (max-width: 60rem) {
    .HomeItem_banner__siixu {
        font-size: 8rem;
    }
}
.HomeItem_darker__3q-gX {
    color: #82101E;
}

/* Search bar function */
.HomeItem_searchSection__1n3UU {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3.25rem;
    margin-bottom: 7rem;
}

.HomeItem_search_box__2m-KB {
    width: 60%;

}

.HomeItem_search__2ooWy {
    width: 100%;
    margin: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.HomeItem_inputField__29qnF {
    margin: 1rem;
    border-radius: 2.85rem;
    border: 0.05rem solid #FFA8A8;
    outline: none;
    box-shadow: 0 0 0.2rem 0 rgba(240, 67, 90, 0.40);
    background-image: url("/images/search.png");
    background-repeat: no-repeat;

}

.HomeItem_inputField__29qnF::-webkit-input-placeholder {
    color: #4E5969;
    font-weight: 350;
    font-size: 1.875rem;
}

.HomeItem_inputField__29qnF::placeholder {
    color: #4E5969;
    font-weight: 350;
    font-size: 1.875rem;
}

/* search button */
.HomeItem_searchButton__19gr_ {
    height: 4.15rem;
    width: 12.5rem;
    border: #82101E;
    border-radius: 5.25rem;
    outline: none;
    margin: 1rem;
    background: linear-gradient(128deg, #ED1522 0%, #E56E48 100%);
    color: #FFA8A8;
    font-size: 1.5rem;
    font-family: 'Noto Sans HK', sans-serif;
    font-weight: 500;

}
@media screen and (max-width: 60rem) {
    .HomeItem_search_box__2m-KB{
        width: 80%;
    }
    .HomeItem_searchButton__19gr_ {
        width: 18rem;
    }
}
.HomeItem_searchButton__19gr_:hover {
    background: linear-gradient(128deg, #ff2241 0%, #980422 100%);

}

.HomeItem_searchButton__19gr_:active {
    background: linear-gradient(128deg, #ff2241 0%, #980422 100%);
    box-shadow: inset 1rem 1rem 3rem #801f2b,
    inset -1rem -1rem 3rem #ad2b3b;
}

/* popular search list */
.HomeItem_popularSearch__iOYyo {
    margin: 1rem;
    color: #CE2A40;
    font-size: 1.5rem;
}

.HomeItem_popularSearchList__A85zv {
    width: 55.55%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.HomeItem_pinkbutton__9faSD {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FEEFEF;
    padding: 1rem 1rem;
    font-size: 1.5rem;
    font-family: 'Exo 2', sans-serif;
    font-weight: 500;
    margin: 0.5rem 0.25rem;
    color: #CE2A40; 
    border-radius: 20rem;
}

.HomeItem_pinkbutton__9faSD:hover {
    background: #ff9b9b;
}

.HomeItem_pinkbutton__9faSD:active {
    background: #FFA8A8;
}

.HomeItem_recentNewsSection__EfgZX {
    width: calc(66.66% - 1rem);
    display: flex;
    flex-direction: column;
}

.HomeItem_recentNewsHeader__2MTPb {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

}

.HomeItem_headerText__29wrO {
    display: flex;
    flex-direction: column;

}

.HomeItem_headerText__29wrO h1 {
    margin: 0;
    color: #F0435A;
    font-size: 1.8rem;
    font-family: 'Exo 2', sans-serif;
    font-weight: 700;
}

.HomeItem_headerText__29wrO p {
    margin: 0.5rem 0 0 0;
    color: #B09EA0;
    font-family: "Exo 2", sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


.HomeItem_news_time_constrain__3YZcH {
    display: flex;
    height: 3rem;
    padding: 0.5rem 1.25rem;
    align-items: center;
    justify-content: center;
    border-radius: 6rem;
    background: #F9F7F6;

    color: #D92121;
    font-family: "Noto Sans HK", sans-serif;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: normal;
}

.HomeItem_recentNewsSelector__Dc_D9 {
    display: flex;
    align-items: center;

}

.HomeItem_recentNewsSelector__Dc_D9 p {
    margin: 0.25rem;
    color: #FFA8A8;
    font-size: 1.2rem;
    font-family: 'Exo 2', sans-serif;
    font-weight: 500;
}
@media screen and (max-width: 60rem) {
    .HomeItem_recentNewsSection__EfgZX {
        font-size: 4rem;
    }
}
.HomeItem_recentNewsContent__wu4qh {
    display: grid;
    grid-gap: 2.5rem;
    gap: 2.5rem;
    grid-template-columns: 12fr;
}

.HomeItem_news__1N2rA p {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(12, 1fr);
    grid-gap: 1rem 2.5rem;
    gap: 1rem 2.5rem;
    grid-auto-flow: dense;
    grid-template-areas:
    "a a a a a b b b b b b b"
    "a a a a a b b b b b b b"
    "a a a a a c c c c c c c"
    "a a a a a c c c c c c c"
    "a a a a a d d d d d d d"
    "a a a a a d d d d d d d"
    "e e e e e e f f f f f f"
    "e e e e e e f f f f f f"
    "g g g g g g h h h h h h"
    "g g g g g g h h h h h h"
    "i i i i i i j j j j j j"
    "i i i i i i j j j j j j";
}


.HomeItem_a__2k5Hp {
    grid-area: a;
}

.HomeItem_b__1d2-Z {
    grid-area: b;
}

.HomeItem_c__21Qrs {
    grid-area: c;
}

.HomeItem_d__3Tkby {
    grid-area: d;
}

.HomeItem_e__wv3QV {
    grid-area: e;
}


.HomeItem_f__254KY {
    grid-area: f;
}

.HomeItem_g__2M6eb {
    grid-area: g;
}

.HomeItem_h__1ACxM {
    grid-area: h;
}

.HomeItem_i__3pGyh {
    grid-area: i;
}

.HomeItem_j__30-JF {
    grid-area: j;
}

@media screen and (max-width: 60rem) {
    .HomeItem_news__1N2rA {
        display: flex;
        flex-direction: column;
    }
}

/* learn More button */
.HomeItem_learnMore__3cT8L {
    margin-top: 2rem;
}

.HomeItem_learnMore__3cT8L a {
    color: #FFA8A8;
    font-size: 1rem;
    font-family: 'Exo 2', serif;
    font-weight: 500;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    float: right;
}

/* product section */
.HomeItem_ProductSection__2Rvo7 {
    margin-top: 6.25rem;
    width: 66.66%;
    padding: 3.12rem 3.75rem;
    display: flex;
    flex-direction: column;
}


.HomeItem_productHeader__1z1mE {
    display: flex;
    flex-direction: column;
}

.HomeItem_productHeader__1z1mE h1 {
    display: flex;
    flex-direction: row;
    grid-gap: 0.62rem;
    gap: 0.62rem;
    color: #D92121;
    font-size: 1.8rem;
    font-family: 'Exo 2', sans-serif;
    font-weight: 700;
}

.HomeItem_productHeader__1z1mE p {
    color: #B09EA0;
    font-family: "Exo 2", sans-serif;
    font-size: 1.25rem;
    font-weight: 500;
}

.HomeItem_products_container__J7wzB {
    display: grid;
    grid-template-columns: 6fr 4fr;
    grid-gap: 1.25rem;
    gap: 1.25rem;
    padding: 2.5rem 3.75rem;

    border-radius: 3.75rem;
    border: 0.1rem solid #F4586E;
    background: rgba(254, 239, 239, 0.70);
}

@media screen and (max-width: 90rem) {
    .HomeItem_products_container__J7wzB {
        display: flex;
        flex-direction: column;
    }
}

/* popular Banks Section */
.HomeItem_popularBanksSection__1vy_R {
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
    padding: 2.5rem;
    width: 66.66%;
    border-radius: 0.5rem;
    border: 0.1rem solid #F9F7F6;
    background-size: 100%;
    background-blend-mode: hard-light, normal;
}

.HomeItem_banklist__3plll {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.HomeItem_arrowimg__2PouM {
    width: 15%;
}

.HomeItem_box__2k-p1 {
    display: grid;
    grid-template-columns: 6.4fr 3.2fr;
    grid-gap: 2rem;
    gap: 2rem;
    width: 66.66%;
    margin-top: 6.25rem;
    align-items: center;
}

@media screen and (max-width: 70rem) {
    .HomeItem_box__2k-p1 {
        display: flex;
        flex-direction: column;
    }

}

.HomeItem_researchReportSection__1DduI {
    width: 100%;
    margin-bottom: 5rem;
    padding: 2.5rem 2rem 3rem 2rem;
    border-radius: 3rem;
    border: 0.05rem solid #F9F7F6;
    overflow: auto;

}

.HomeItem_report__3ZQjT {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
}

.HomeItem_report__3ZQjT img {
    width: 5rem;
    height: 6.5rem;
    margin-right: 2rem;
    object-fit: cover;
}

.HomeItem_reportTitle__3AgPW {
    display: flex;
    flex-direction: column;
}

.HomeItem_reportTitle__3AgPW h2 {
    color: black;
}

.HomeItem_reportTitle__3AgPW p {
    color: rgb(128, 128, 128);
}

.HomeItem_nbanker__PbMLV {
    display: flex;
    padding: 5rem 2rem 3rem 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 3.75rem;
    gap: 3.75rem;

    border-radius: 3rem;
    background: linear-gradient(100deg, #ED1522 -1.65%, #E56E48 82.54%);
}

.HomeItem_text__3S5ac {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    color: #FEEFEF;
    font-size: 1.1rem;
    font-family: Noto Sans HK, sans-serif;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.HomeItem_product_bank__19N5U {
    display: flex;
    flex-direction: column;
}

.HomeItem_product_bank__19N5U h1 {
    color: #D92121;
    font-family: "Exo 2", sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.HomeItem_link__1L8pQ {
    color: #FFA8A8;
    text-align: right;
    font-family: "Exo 2", sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
}




/* The follow is for AI ChatBot */
.HomeItem_vector__2MpyD {
    position: fixed;
    width: 8rem;
    height: 8rem;
    margin: 30px 0 0 30px;
    bottom:3rem;
    right:3rem;
    background: url(/images/ChatCircleDots.png);
    background-size: 100% 100%;
    z-index: 3;
    border:none;
  }



:root {
  --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
}

/* .main_container {
  overflow: hidden;
}

.main_container,
.main_container * {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  outline: 0;
} */

.HomeItem_main_container__34L9q {
  position: fixed;
  display: flex;
  width: 24.375rem;
  height: 39.813rem;
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 1.3rem;
  box-shadow: 0rem 0rem 1rem rgba(206, 42, 64, 0.25);
  z-index: 4;
  bottom:6rem;
  right:12rem;
  flex-direction: column;
  flex-wrap:wrap;
}

.HomeItem_chatbot_header__2QC6q{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: row;
    border-bottom: 0.1rem solid rgba(206, 42, 64, 0.25);
    position: relative;
}

.HomeItem_chatbot_header__2QC6q img{
    object-position: center;
    width:5rem;
}

.HomeItem_banker__3QAgw {
    height: 1.125rem;
    color: #82101E;
    font-family: Noto Sans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
    font-family: Noto Sans, var(--default-font-family);
    font-size: 0.943rem;
    font-weight: 600;
    text-align: left;
  }

.HomeItem_header_right__1XRKN{
    display: flex;
    height: 2rem;
    position: absolute;
    bottom:0.3rem;
    right:2rem;
}

.HomeItem_insightful_precise__3rqw_ {
    display: flex;
    position: relative;
    color: #4F050E;
    font-family: Exo 2,"Semibold", sans-serif;
    font-size: 0.75rem;
    font-weight: 600;
    padding-left:1rem;
    margin-bottom: auto;
  }
  .HomeItem_neobank_statistic__2rwnN {
    display: flex;
    position: relative;
    color: #D92121;
    font-family: Exo 2, "Medium", sans-serif;
    font-style: italic;
    font-size: 0.75rem;
    font-weight: 500;
    padding-left: 1rem;
  }

/* chatbox的container */
.HomeItem_chatbot_messages__P9ylD {
    height: 30rem;
    padding: 0.4rem;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
}

.HomeItem_userMessageBubble__y5fLN {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 17.938rem;
    padding: 0.625rem;
    background: #ffffff;
    border: 0.026rem solid #ffa7a7;
    border-radius: 1.25rem  1.25rem 0.125rem 1.25rem ;
    justify-content: flex-start;
    font-family: Exo 2, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
    font-family: Exo 2, var(--default-font-family);
    color:#D92121;
    font-size: 0.626rem;
    font-weight: 700;
    text-align: left;
    margin-top: 0.5rem;
    margin-left:auto;
  }

  .HomeItem_banker_gpt__HMbf0 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 1.063rem;
    color: #D92121;
    font-family: Exo 2, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
    font-family: Exo 2, var(--default-font-family);
    font-size: 0.875rem;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    white-space: nowrap;
  }

  .HomeItem_botMessageBubble__3xcg5 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 17.938rem;
    padding: 0.625rem;
    background: #feefef;
    border: 1px solid #feefef;
    border-radius: 1.25rem 1.25rem 1.25rem 0.125rem;
    font-size: 0.626rem;
    text-align: left;
    margin-top: 0.5rem;
    margin-right:auto;
  }

  .HomeItem_bottom_container__2nFcC{
    border-top: 0.1rem solid rgba(206, 42, 64, 0.25);
    display: flex;
    flex-wrap:wrap;
    flex-direction: row;
    position: absolute;
    bottom:0;
    padding: 0.3rem 0rem;
    background-color: white;
    z-index:5;
}

.HomeItem_li_tag__X85Um {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    position: relative;
    overflow: hidden;
    padding:0.5rem 0.2rem 0rem 0.2rem;
  }
  
  .HomeItem_keyword__1ImJc {
    position: relative;
    height: 0.563rem;
    color: #D92121;
    font-family: Noto Sans HK, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
    font-family: Noto Sans HK, var(--default-font-family);
    font-size: 0.7rem;
    font-weight: 500;
    line-height: 0.563rem;
    text-align: center;
    display: flex;
    align-items: center;;
    padding: 0.5rem 0.604rem 0.5rem 0.604rem;
    background: #feefef;
    border: none;
    border-radius: 2.108rem;
  }
  
  .HomeItem_chatbotMessage__-m0rc {
    padding: 0.5rem;
  }

  .HomeItem_chatbotMessage__-m0rc input {
    background-color: #ffffff;
    box-shadow: 0 0 0.6rem #FBD0D6;
    width: 19rem;
    flex-grow: 1;
    padding: 0.3rem 1rem;
    border: none;
    border-radius: 10px;
    font-size: 8px;
    outline: #FBD0D6;
    }

    .HomeItem_chatbotMessage__-m0rc button {
    margin-left: 0.4rem;
    padding: 0.3rem 0.5rem;
    border: none;
    border-radius: 20px;
    background: linear-gradient(128.33deg, #ec1421, #e56d47);
    color: #ffa7a7;
    font-size: 1.2rem;
    cursor: pointer;
    }


.SectionBox_section__kYDC4{
    display: flex;
    flex-direction: column;
    margin: 0;
    position: relative;
}

.SectionBox_subsection__1uGbm{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 1.65rem 0;
}

.SectionBox_header__1RIsB{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    align-items: center;
}

.SectionBox_header__1RIsB h1{
    color: #82101E;
    font-family: "Exo 2", sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 2.55rem;
    
}

.SectionBox_header__1RIsB span{
    color: #CE2A40;
    font-size: 1.2rem;
    font-family: "Exo 2", sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.SectionBox_verticalDividedSection__2f3K1{
    width: 66.66%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 3.75rem 0;

}

@media screen and (max-width: 60rem) {
    .SectionBox_verticalDividedSection__2f3K1 {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .SectionBox_leftNode__1Se8q {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .SectionBox_rightNode__3Eavb {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .SectionBox_verticalLine__2N2wW {
        display: none;
    }
}

.SectionBox_leftNode__1Se8q{
    width: 45%;
    display: flex;
    flex-direction: column;
}

.SectionBox_rightNode__3Eavb{
    width: 45%;
    display: flex;
    flex-direction: column;
}

.SectionBox_verticalLine__2N2wW{
    width: 0.05rem;
    height: auto;
    background-color: #FEEFEF;
}

.SectionBox_miniSection__1cEqv{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 1.65rem 0;
}

.SectionBox_miniSection__1cEqv h1{
    color:  #FFA8A8;
    font-family: "Inter", sans-serif;
    font-size: 1.252rem;
    font-style: normal;
    font-weight: 700;
    line-height: 187.75%; /* 1.502rem */
    letter-spacing: -0.019rem;
}

.SectionBox_wideContentSectionBox__hGZIM{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 1.65rem 0;
    align-items: center;
}

.SectionBox_wider__header__2Kqco{
    display: flex;
    width: 66.66%;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}

.SectionBox_wider__header__2Kqco h1{
    color: #CE2A40;
    font-family: "Exo 2", sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.SectionBox_wider__header__2Kqco h2{
    color: #FFA8A8;
    font-family: "Inter", sans-serif;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 1.8rem */
    letter-spacing: -0.0228rem;
}

.SectionBox_overlay__Jwy8V {
    position: absolute;
    top: 2.9rem;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(254, 239, 239, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    z-index: 1;
    border: 0.1rem solid #F4586E;
    border-radius: 3rem;
    -webkit-backdrop-filter: blur(0.25rem);
            backdrop-filter: blur(0.25rem);
    
}

.SectionBox_overlay__Jwy8V h1 {
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    font-family: "Exo 2", sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: linear-gradient(100deg, #ED1522 -1.65%, #E56E48 82.54%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.NewsCard_card_vertical__K0Oxp {
    display: flex;
    flex-direction: column;
    padding: 1.25rem;
    grid-gap: 0.7rem;
    gap: 0.7rem;
    border-radius: 0.3125rem;
    border: 0.1rem solid #F9F7F6;
    transition: -webkit-filter 0.3s ease;
    transition: filter 0.3s ease;
    transition: filter 0.3s ease, -webkit-filter 0.3s ease;
}

.NewsCard_card_vertical__K0Oxp:hover {
    /* box-shadow: 0 0 0.25rem #801f2b; */
    -webkit-filter: brightness(0.9);
            filter: brightness(0.9); 
    background-color: #FAFAFA;
}

.NewsCard_card__1B1ux {
    display: flex;
    grid-template-columns: 1fr 2fr;
    padding: 0.7rem;
    grid-gap: 1.25rem;
    gap: 1.25rem;
    border-radius: 0.3125rem;
    border: 0.1rem solid #F9F7F6;
    transition: -webkit-filter 0.3s ease;
    transition: filter 0.3s ease;
    transition: filter 0.3s ease, -webkit-filter 0.3s ease;
    width: 107.5rem;
    
}


.NewsCard_card_no_img__bLSbW {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0.7rem;
    grid-gap: 1.25rem;
    gap: 1.25rem;
    border-radius: 0.3125rem;
    border: 0.1rem solid #F9F7F6;
    transition: -webkit-filter 0.3s ease;
    transition: filter 0.3s ease;
    transition: filter 0.3s ease, -webkit-filter 0.3s ease;
}


.NewsCard_card_no_img2container__1CZvG {
    width: 100%;
    height: auto;
}


.NewsCard_card_no_img2__32D51 {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0.7rem;
    grid-gap: 1.25rem;
    gap: 1.25rem;
    border-radius: 0.3125rem;
    border: 0.1rem solid #F9F7F6;
    transition: -webkit-filter 0.3s ease;
    transition: filter 0.3s ease;
    transition: filter 0.3s ease, -webkit-filter 0.3s ease;
    width: 108.5rem;
    height: 14.5rem;
    padding-top: 0.7rem;
}




.NewsCard_card_no_img__bLSbW:hover {
    /* box-shadow: 0 0 0.25rem #801f2b; */
    -webkit-filter: brightness(0.9);
            filter: brightness(0.9);
    background-color: #FAFAFA;
}

.NewsCard_card__1B1ux:hover {
    /* box-shadow: 0 0 0.25rem #801f2b; */
    -webkit-filter: brightness(0.9);
            filter: brightness(0.9); 
    background-color: #FAFAFA;
}


.NewsCard_card_content__blDZ- {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.NewsCard_footnote__1UbgQ {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}





.NewsCard_card_img_vertical__3Ijhp {
    display: flex;
    justify-content: center;
    align-items: center;
}

.NewsCard_card_img_vertical__3Ijhp img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.25rem;
}

.NewsCard_card_img__1cz4f {
    display: flex;
    justify-content: center;
    align-items: center;
    
    overflow: hidden;
}

.NewsCard_card_img__1cz4f img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 0.25rem;
}

.NewsCard_tags__1V1FY {
    display: flex;
    flex-direction: row;
    grid-gap: 1.5rem;
    gap: 1.5rem;
}

.NewsCard_tag__5VdAK {
    color: #FFA8A8;
    font-family: Inter, serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 1.5rem */
    letter-spacing: -0.019rem;
    padding-left: 1.5rem;
}

.NewsCard_date__UQDuw {
    color: #B09EA0;
    font-family: "Exo 2", sans-serif;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-left: 100rem;
}

.NewsCard_card_title__3qn2I {
    color: #4F050E;
    font-family: "Exo 2", sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 1.5rem;
}

.NewsCard_card_preview__15Kex {
    color: #B09EA0;
    font-family: "Exo 2", "Noto Sans HK", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 4.125rem;
    padding-left: 1.5rem;
    white-space: nowrap;
}


.NewsCard_news_img__Cgjvi {
    display: flex;
    position: absolute;
    width: 15rem;
    height: 7rem;
    margin-left: 91rem;
    padding-bottom: 1.3rem;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.BankCard_bank__1VrKd {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 1rem;
    border-radius: 0.9rem;
    transition: -webkit-filter 0.3s ease;
    transition: filter 0.3s ease;
    transition: filter 0.3s ease, -webkit-filter 0.3s ease;
}

.BankCard_bank__1VrKd:hover{
    -webkit-filter: brightness(0.9);
            filter: brightness(0.9);
    background-color: #FAFAFA;
}

.BankCard_bankinfo__2_xwz {
    display: flex;
    flex-direction: row;
    align-items: center;

}


@media screen and (max-width: 60rem) {
    .BankCard_banklist__bwh2s {
        display: flex;
        flex-direction: column;
    }

}

.BankCard_bankico___tW5I {

    border-radius: 0.5rem;
    margin: 1rem;
    height: 5rem;
    width: 5rem;
    object-fit: contain;
}

.BankCard_bankTitle__21Cxd {
    margin: 0;
    padding: 0;
}

.BankCard_bankTitle__21Cxd h2 {
    color: #4F050E;
    font-family: "Exo 2", sans-serif;
    font-size: 2.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.BankCard_bankTitle__21Cxd p {
    color: #FFA8A8;
    font-family: "Exo 2", sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}



.ProductCard_product_card__1yp74 {
    padding: 1.25rem 0.62rem;
    display: flex;
    flex-direction: column;
    grid-gap: 1.25rem;
    gap: 1.25rem;
    justify-content: center;
    align-items: center;
    height: auto;
    border-radius: 1.25rem;
    background: #FFFFFF;
}



.ProductCard_na_product_card__Jp6ss {
    height: 100%;
    padding: 1.25rem 0.62rem;
    display: flex;
    flex-direction: column;
    grid-gap: 1.25rem;
    gap: 1.25rem;
    justify-content: center;
    align-items: center;
    width: 19.875rem;
    height: 18.813rem;
    border-radius: 1.25rem;
    background: #F9F7F6;
}

.ProductCard_na_product_card2__1hKP_ {
    height: 100%;
    padding: 1.25rem 0.62rem;
    display: flex;
    flex-direction: column;
    grid-gap: 1.25rem;
    gap: 1.25rem;
    justify-content: center;
    align-items: center;
    width: 318px;
    height: 351px;
    border-radius: 1.25rem;
    background: #F9F7F6;
}

.ProductCard_na_product_card__Jp6ss p {
    color: #4E5969;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 1.625rem */
    text-transform: capitalize;
}

.ProductCard_product_type__1Ebs4 {
    color: #FFA8A8;
    text-align: center;
    font-family: Inter,sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 2.25rem */
    letter-spacing: -0.033rem;
}

.ProductCard_product_name__1yIso {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    color: #4F050E;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 3rem;
    font-style: normal;
    font-weight: 500;
    line-height: 3.631rem;
    letter-spacing: -0.066rem;

    background: linear-gradient(100deg, #ED1522 -1.65%, #E56E48 82.54%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.ProductCard_na_product_name__e4FQO {
    color: #86909C;
    font-family: Inter, sans-serif;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 2.925rem */
    text-transform: capitalize;
}

.ProductCard_product_name_black__IHUa9{
    color: #1D2129;
    text-align: center;
    font-family: Inter,sans-serif;
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 4.5rem */
    letter-spacing: -0.066rem;
}

.ProductCard_product_card__1yp74 p {
    padding: 0 1.25rem;
    overflow: hidden;
    color: #B09EA0;
    text-overflow: ellipsis;
    font-family: Noto Sans HK, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    
}

.ProductCard_product_card__1yp74 ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 0.62rem;
    gap: 0.62rem;
    justify-content: center;
    align-items: center;
    list-style: none;
}

.ProductCard_product_card__1yp74 ul li {
    display: flex;
    padding: 0.62rem 1.25rem;

    color: #D92121;
    font-family: Noto Sans HK, sans-serif;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    border-radius: 6.54rem;
    background: #FEEFEF;
}
.TabAndPaginationBox_tabBox__TylK4{
    display:flex;
    flex-direction:column;
    margin-top: 1.5rem;
}

.TabAndPaginationBox_tabBar__3Asdl{
    display:flex;
    grid-gap: 0.5rem 1rem;
    gap: 0.5rem 1rem;
    flex-direction:row;
    align-items:center;
    flex-wrap: wrap;
}

.TabAndPaginationBox_tabBar2ndStyle__1AVMo{
    display:flex;
    grid-gap: 0.5rem 1.35rem;
    gap: 0.5rem 1.35rem;
    flex-direction:row;
    align-items:center;
    flex-wrap: wrap;
}

.TabAndPaginationBox_inactiveTab2ndStyle__3j9Qp{
    padding: 0.75rem 1.25rem;

    color:  #CE2A40;
    font-family: "Noto Sans HK", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    border-radius: 5.25rem;
    background:  #FEEFEF;

}

.TabAndPaginationBox_activeTab2ndStyle__2jp58{
    padding: 0.75rem 1.25rem;

    color:  #CE2A40;
    font-family: "Noto Sans HK", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    border-radius: 5.25rem;
    background:  #FFA8A8;
}

.TabAndPaginationBox_inactiveTab2ndStyle__3j9Qp:hover{
    background-color: #FFF6F6;
}

.TabAndPaginationBox_inactiveTab2ndStyle__3j9Qp:active{
    background-color: #FEEFEF;
}

.TabAndPaginationBox_inactiveTab__1otZ0{
    padding: 0.65rem 1.25rem;
    color: #82101E;
    font-family: "Noto Sans HK", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28.96px;
    border-bottom: 0.1rem solid #F0435A;
}

.TabAndPaginationBox_inactiveTab__1otZ0:hover{
    background-color: #FFF6F6;
}

.TabAndPaginationBox_inactiveTab__1otZ0:active{
    background-color: #FEEFEF;
}

.TabAndPaginationBox_activeTab__2fYfd{
    padding: 0.65rem 1.25rem;


    color: #82101E;
    font-family: "Noto Sans HK", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28.96px;
    background-color: #FEEFEF;
    border-bottom: 0.1rem solid #F0435A;
}

.TabAndPaginationBox_tabContent__3EqEG {
    width: 100%;
}

.TabAndPaginationBox_paginationBox__1lAT7{
    display:flex;
    flex-direction:column;
    
}


.TabAndPaginationBox_paginationBoxFooter__17lNs{
    width: 100%;
}
.TabAndPaginationBox_paginationBoxFooterButtons__1K2RG{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    margin-top: 1.5rem;
}
.TabAndPaginationBox_loadBtn__3vhiY{
    margin: 0 4rem;
    color:  #FFA8A8;
    text-align: right;
    font-family: "Exo 2", sans-serif;
    font-size: 0.85rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    cursor: pointer;
}

.TabAndPaginationBox_indexBox__1yGPU{
    max-width: 70%;
    display:flex;
    flex-wrap: wrap;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    cursor: pointer;
    /* border-left: 0.05rem solid #A75862; */
    padding: 0 0.85rem;
    /* border-right:0.05rem solid #A75862; */
}

.TabAndPaginationBox_indexBox__1yGPU:hover{
    cursor: pointer;
}

.TabAndPaginationBox_index__3pn51{
    margin: 0 0.85rem;
    color:#A75862;
    font-family: "Exo 2", sans-serif;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.TabAndPaginationBox_activeIndex__1aVd9{
    margin: 0 0.85rem;
    color: #F0435A;
    font-family: "Exo 2", sans-serif;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
}

.TabAndPaginationBox_arrowButton__J1wmr {
    background-color: transparent;
    border: none;
    color: #980422;
    font-size: 24px;
}


.LoadingSign_loading__2zlKi{
    display: flex;
    flex-direction: column;
    grid-gap: 1vh;
    gap: 1vh;
    width: 100%;
    justify-content: center;
    align-items: center;

    color: #82101E;
    font-size: 3em;
    font-family: 'Exo 2', sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

}

.LoadingSign_spinner__2mZvM {
    border: 0.2rem solid rgba(0, 0, 0, 0.1);
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
    border-left-color: #F4586E;
    -webkit-animation: LoadingSign_spin__3EEcB 1s ease infinite;
            animation: LoadingSign_spin__3EEcB 1s ease infinite;
}

@-webkit-keyframes LoadingSign_spin__3EEcB {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes LoadingSign_spin__3EEcB {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.ProfileMenu_profileMenu__1zCff {
  position: relative;
}

.ProfileMenu_profileIconButton__2yRY6 {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  height: 73px;
  width: 73px;
}

.ProfileMenu_profilePopover__3ObZM {
  position: absolute;
  top: 100%; /* Position below the button */
  right: 0;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 8px;
  display: flex;
  flex-direction: column;
}

.ProfileMenu_profilePopoverButton__2MTwh {
  margin: 5px 0;
  padding: 8px;
  width: 100%;
  background: none;
  cursor: pointer;
  font-family: "Exo 2";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
}

.ProfileMenu_profilePopoverButton__2MTwh:nth-child(-n+2) {
  color: #82101E;
}

.ProfileMenu_profilePopoverButton__2MTwh:last-child {
  background: white;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(100deg, #F4586E -1.65%, #FDE1E1 82.54%);
}

.ProfileMenu_profilePopoverButton__2MTwh:focus,
.ProfileMenu_profilePopoverButton__2MTwh:hover {
  text-decoration: underline;
  text-decoration-color: black;
  -webkit-text-decoration-color: black;
}
.NewsAndReportsItems_filterContainer__krsAC {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 3.125rem auto 1.25rem;
  padding: 1.25rem;
  width: 80%;
  height: auto;
}

.NewsAndReportsItems_header__2nFPs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: 20px;
}

.NewsAndReportsItems_NewsAndLocationContainer__3FxP0 {
  white-space: nowrap;
  position: relative;

}

.NewsAndReportsItems_news__20GqU {
  color: #D92121;
  font-family: "Exo 2";
  font-size: 6rem;
  font-family: Exo 2,"Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.NewsAndReportsItems_inLocation__1fnOy {
  
  margin-top: 5.375rem;
  margin-left: 75rem;
  margin-right: 0.375rem;
  color: #FFA8A8;
  font-family: "Exo 2";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
  
  
}

.NewsAndReportsItems_location__3eAyt {
  display: flex;
  padding-top: 16.25rem;
  padding: 0.5rem 1.25rem; /* 相当于 8px 20px / 16px */
  margin-top: 5rem; /* 相当于 8px / 16px */
  border-radius: 6.5449375rem; /* 相当于 104.719px / 16px */
  background: #FEEFEF;
  color: #D92121;
  font-family: "Noto Sans HK";
  font-size: 1.25rem; /* 相当于 20px / 16px */
  font-style: normal;
  font-weight: 501;
  line-height: normal;
  white-space: nowrap;
}



 /*  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 1.25rem;  相当于 8px 20px / 16px 
  margin-top: 0.5rem;  相当于 8px / 16px 
  border-radius: 6.5449375rem; /* 相当于 104.719px / 16px 
  background: #FEEFEF;
  color: #D92121;
  font-family: "Noto Sans HK";
  font-size: 1.25rem;  相当于 20px / 16px 
  font-style: normal;
  font-weight: 501;
  line-height: normal;
 */
 
 .NewsAndReportsItems_filterOptions_container__2Jf7p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
}



.NewsAndReportsItems_filterOptions__xThel {
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 0.625rem;
  margin-right: 39rem;
}


.NewsAndReportsItems_filterOptionsHeader__1XB7v {
  display: inline;
  flex-direction: row;
  justify-content: center;
  margin-right: 1.25rem;
  margin-bottom: 0.313rem;
  color: #D92121;
  font-family: "Noto Sans HK";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.466px;
}


.NewsAndReportsItems_select_container__1uz7_{
  display: inline-flex;
  justify-content: center;
  position: relative;
  margin-left: 86rem;
  white-space: nowrap;
  -webkit-transform: translateY(-3.8rem);
          transform: translateY(-3.8rem);
}


.NewsAndReportsItems_select_container__1uz7_ .NewsAndReportsItems_icon_container__1AhFU{
  width: 2.7rem;
  height: 1.55rem;
  margin-left: -3rem;
  margin-top: -3rem;
  position: inherit;
  right: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #023ca9;
  background: url(/images/Vector.png);
  background-repeat: no-repeat;
  background-size: cover;
}

icon-container i{
  font-size: 30px;
  color: white;
}

.NewsAndReportsItems_select_box__216jC{
  border: none;
  -webkit-appearance: none;
          appearance: none;
  padding: 0 30px 0 15px;
  color: #D92121;
  background-color: #FEEFEF;
  font-family: "Noto Sans HK";
  font-size: 1.5rem; /* 相当于 20px / 16px */
  font-style: normal;
  font-weight: 501;
  line-height: normal;
  text-align: center;
}



.NewsAndReportsItems_pinkbutton__2ZnBQ {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FEEFEF;
    padding: 0 0.4rem;
    font-size: 1rem;
    font-family: 'Exo 2', sans-serif;
    font-weight: 500;
    margin: 0.5rem 0.25rem;
}

.NewsAndReportsItems_pinkbutton__2ZnBQ:active {
  background-color: pink;
  /* 其他样式 */
}

.NewsAndReportsItems_pinkbutton__2ZnBQ:focus {
  background-color: pink;
  /* 其他样式 */
}

.NewsAndReportsItems_divider__1dvZO {
  width: 108.5rem;
  height: 0.063rem;
  background-color: #ccc;
  margin-bottom: 1.25rem;
}


.NewsAndReportsItems_card_img__N9uOY {
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(/images/WebLogo.png);
  overflow: hidden;
}

.NewsAndReportsItems_paginationBoxFooter__Qg9Qg{
  width: 100%;
}
.NewsAndReportsItems_paginationBoxFooterButtons__39CFx{
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  margin-top: 1.5rem;
}
.NewsAndReportsItems_loadBtn__3zreP{
  margin: 0 4rem;
  color:  #FFA8A8;
  text-align: right;
  font-family: "Exo 2", sans-serif;
  font-size: 0.85rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  cursor: pointer;
}

.NewsAndReportsItems_indexBox__1z0M0{
  max-width: 70%;
  display:flex;
  flex-wrap: wrap;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  cursor: pointer;
  /* border-left: 0.05rem solid #A75862; */
  padding: 0 0.85rem;
  /* border-right:0.05rem solid #A75862; */
}

.NewsAndReportsItems_indexBox__1z0M0:hover{
  cursor: pointer;
}

.NewsAndReportsItems_index__3dr_l{
  margin: 0 0.85rem;
  color:#A75862;
  font-family: "Exo 2", sans-serif;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.NewsAndReportsItems_activeIndex__4pi5X{
  margin: 0 0.85rem;
  color: #F0435A;
  font-family: "Exo 2", sans-serif;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.NewsAndReportsItems_arrowButton__2KqRc {
  background-color: transparent;
  border: none;
  color: #980422;
  font-size: 24px;
}


.NewsAndReports_index__zptTO{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
:root {
  --brown: rgba(176, 158, 160, 1);
  --grey: rgba(249, 247, 246, 1);
  --lightfillcolor-fill-1: rgba(247, 248, 250, 1);
  --lighttextcolor-text-2: rgba(78, 89, 105, 1);
  --med-20-font-family: "Noto Sans HK", Helvetica;
  --med-20-font-size: 20px;
  --med-20-font-style: normal;
  --med-20-font-weight: 500;
  --med-20-letter-spacing: 0px;
  --med-20-line-height: normal;
  --reddark: rgba(79, 5, 14, 1);
  --redfeefef: rgba(251, 208, 214, 1);
  --redmid: rgba(217, 33, 33, 1);
  --redmidhovered: rgba(130, 16, 30, 1);
  --redpink: rgba(254, 239, 239, 1);
  --redpinkhovered: rgba(255, 168, 168, 1);
  --white: rgba(255, 255, 255, 1);
}

