.section{
    display: flex;
    flex-direction: column;
    margin: 0;
    position: relative;
}

.subsection{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 1.65rem 0;
}

.header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    align-items: center;
}

.header h1{
    color: #82101E;
    font-family: "Exo 2", sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 2.55rem;
    
}

.header span{
    color: #CE2A40;
    font-size: 1.2rem;
    font-family: "Exo 2", sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.verticalDividedSection{
    width: 66.66%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 3.75rem 0;

}

@media screen and (max-width: 60rem) {
    .verticalDividedSection {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .leftNode {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .rightNode {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .verticalLine {
        display: none;
    }
}

.leftNode{
    width: 45%;
    display: flex;
    flex-direction: column;
}

.rightNode{
    width: 45%;
    display: flex;
    flex-direction: column;
}

.verticalLine{
    width: 0.05rem;
    height: auto;
    background-color: #FEEFEF;
}

.miniSection{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 1.65rem 0;
}

.miniSection h1{
    color:  #FFA8A8;
    font-family: "Inter", sans-serif;
    font-size: 1.252rem;
    font-style: normal;
    font-weight: 700;
    line-height: 187.75%; /* 1.502rem */
    letter-spacing: -0.019rem;
}

.wideContentSectionBox{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 1.65rem 0;
    align-items: center;
}

.wider__header{
    display: flex;
    width: 66.66%;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}

.wider__header h1{
    color: #CE2A40;
    font-family: "Exo 2", sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.wider__header h2{
    color: #FFA8A8;
    font-family: "Inter", sans-serif;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 1.8rem */
    letter-spacing: -0.0228rem;
}

.overlay {
    position: absolute;
    top: 2.9rem;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(254, 239, 239, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    z-index: 1;
    border: 0.1rem solid #F4586E;
    border-radius: 3rem;
    backdrop-filter: blur(0.25rem);
    
}

.overlay h1 {
    display: flex;
    gap: 0.5rem;
    font-family: "Exo 2", sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: linear-gradient(100deg, #ED1522 -1.65%, #E56E48 82.54%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

